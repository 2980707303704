import React, { FC } from 'react';

import { LoadingAnimation } from 'views/components/loading-animation';
import { Props, Root, Header, Main } from './index.styled';

const InterfaceLoading: FC<Props> = ({ header = true }) => {
  return (
    <Root>
      {header && <Header />}
      <Main header={header}>
        <LoadingAnimation />
      </Main>
    </Root>
  );
};

export { InterfaceLoading };