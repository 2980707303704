import React, { FC, PropsWithChildren } from 'react';

import { Props, Root, Header, Main} from './index.styled';

const NoInterfaceData: FC<Props & PropsWithChildren> = ({ header = true, children }) => {
  return (
    <Root>
      {header && <Header />}
      <Main header={header}>
        {children}
      </Main>
    </Root>
  );
};

export { NoInterfaceData };
