import styled from 'styled-components';

import { baseFontFamily, baseFontSize } from 'views/services/font-scheme';
import { baseColor, lightTextColor, darkPrimaryColor } from 'views/services/color-scheme';

import { headerHeight } from 'views/components/header';

export interface Props {
  header?: boolean;
}

export const Root = styled.div`
`;

export const Header = styled.div`
  height: ${headerHeight};
  background-color: ${darkPrimaryColor};
`;

export const Main = styled.div<Props>`
  font-family: ${baseFontFamily};
  font-size: calc(${baseFontSize} * 2);
  color: ${lightTextColor};
  background-color: ${baseColor};
  height: calc(100vh - ${headerHeight} - ${p => p.header ? headerHeight : '0px'});
  display: flex;
  justify-content: center;
  align-items: center;
`;
