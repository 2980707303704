import { OriginalMenuCategory, OriginalMenuCategoryCollection } from 'models/entities/original-menu-category';

import { Condition } from './models/condition';

class Model {

  readonly condition: Condition;
  readonly collection?: OriginalMenuCategoryCollection;
  readonly list?: OriginalMenuCategory[];

  constructor() {
    this.condition = new Condition();
  }

  async read(): Promise<this> {
    const collection = await OriginalMenuCategoryCollection.read();
    const list = this.condition.filter(collection.documents);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, collection, list });
  }

  async readAllCollection(): Promise<this> {
    if (!this.collection) throw new Error('collection is undefined');
    const collection = await this.collection.readAll();
    const list = this.condition.filter(collection.documents);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, collection, list });
  }

  build(): OriginalMenuCategory  {
    if (!this.collection) throw new Error('collection is undefined');
    return OriginalMenuCategory.buildNext(this.collection.documents);
  }

  add(category: OriginalMenuCategory): this {
    if (!this.collection) throw new Error('collection is undefined');
    return this.apply(this.collection.add(category));
  }

  replace(category: OriginalMenuCategory): this {
    if (!this.collection) throw new Error('collection is undefined');
    return this.apply(this.collection.replace(category));
  }

  remove(category: OriginalMenuCategory): this {
    if (!this.collection) throw new Error('collection is undefined');
    return this.apply(this.collection.remove(category));
  }

  private apply(collection: OriginalMenuCategoryCollection): this {
    const list = this.condition.filter(collection.documents);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, collection, list });
  }

}

export { Model };